import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import HeroFeature from "../../../components/heroes/hero-feature"
import {injectIntl} from "gatsby-plugin-react-intl"
import Features from "../../../components/features/features";
import FeaturesData from "../../../content/features/automation/index.json";

const FeaturesAutomationPage = ({ intl, location }) => (
    <Layout>
        <Seo
            lang={intl.locale}
            title={FeaturesData[intl.locale].title}
            description={FeaturesData[intl.locale].text}
            pathname={location.pathname}
        />
        <HeroFeature
            theme={`primary`}
            content={FeaturesData[intl.locale]}
        />
        <Features features={FeaturesData[intl.locale].features} intro={FeaturesData[intl.locale].intro} />
    </Layout>
)

export default injectIntl(FeaturesAutomationPage)
